import { Box } from "@mui/material"
import { navigate } from "gatsby"
import React from "react"
import { MainLayout } from "../common/MainLayout"
import { PrimaryButton } from "../common/PrimaryButton"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

export type HowToUseProps = {}

export const HowToUse: React.FunctionComponent<HowToUseProps> = () => {
  return (
    <MainLayout>
      <div className="c-feature">
        <div className="c-feature__ttl">
          <h3 className="c-feature__ttl--01">登録方法と使い方</h3>
          <p className="c-feature__ttl--02">
            （※初めての方は以下の準備から開始してください。）
          </p>
        </div>
      </div>
      <div className="c-main">
        <div className="c-container mx-auto">
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            手順①（MetaMaskのアカウント作成する）
          </Box>
          <div className="c-content">
            <p className="c-content__txt">
              まず、WalletConnect
              をサポートする仮想通貨ウォレットのアカウントを作成する必要があります。
              <br />
              一般的な例としては、MetaMask、Trust
              Walletなどがあります。各ウォレットのプロセスは非常に似ているため、最適なものを選択してください。
              <br />
              <br />
              *MINT NFTではMetaMaskを推奨しております。
            </p>
            <div className="text-center">
              <PrimaryButton
                sx={{
                  height: 60,
                  width: "100%",
                  maxWidth: 300,
                  fontSize: 21,
                }}
                variant="contained"
                className="inline-flex items-center"
                onClick={() => navigate("/metamask")}
              >
                <span className="flex-1 text-center">詳しくはこちら</span>
                <ChevronRightIcon fontSize="large" fontWeight={600} />
              </PrimaryButton>
            </div>
          </div>
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            手順②(ETHをMetaMaskに入金する)
          </Box>
          <div className="c-content">
            <p className="c-content__txt">
              MetaMaskが用意できたら、イーサリアム(ETH)をMetaMaskに送ります。
              <br />
              Binanceなどの取引所で ETH を購入し、MetaMaskに送金します。
            </p>
            <div className="text-center">
              <PrimaryButton
                sx={{
                  height: 60,
                  width: "100%",
                  maxWidth: 300,
                  fontSize: 21,
                }}
                className="inline-flex items-center"
                variant="contained"
                onClick={() => navigate("/buy_eth")}
              >
                <span className="flex-1 text-center">詳しくはこちら</span>
                <ChevronRightIcon fontSize="large" fontWeight={600} />
              </PrimaryButton>
            </div>
          </div>
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            手順③(SIGN INする)
          </Box>
          <div className="c-content">
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                「METAMASK」アイコンをクリック
              </Box>
              <div className="c-block__img">
                <img
                  src={"/images/how-to-use/img-01.png"}
                  alt="how-to-use-01"
                />
              </div>
              <p className="c-content__txt">
                *「METAMASK」アイコンが表示されない場合
                <br />
                「METAMASK」アイコンが表示されない場合は、右上のメニューより「ウォレット接続」ボタンをクリックします。
              </p>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                「ウォレット接続」ボタンをクリック
              </Box>
              <div className="c-block__img">
                <img
                  src={"/images/how-to-use/img-02.png"}
                  alt="how-to-use-02"
                />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                「次へ」ボタンクリック
              </Box>
              <div className="c-block__img">
                <img
                  src={"/images/how-to-use/img-03.png"}
                  alt="how-to-use-03"
                />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                「接続」をクリックしウォレットを接続する
              </Box>
              <div className="c-block__img">
                <img
                  src={"/images/how-to-use/img-04.png"}
                  alt="how-to-use-04"
                />
              </div>
            </div>
          </div>
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            手順④（コレクションを作る）
          </Box>
          <div className="c-content">
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                「コレクションを作成」をクリック
              </Box>
              <div className="c-block__img">
                <img
                  src={"/images/how-to-use/img-05.png"}
                  alt="how-to-use-05"
                />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                必須項目を入力しコレクションを作成する
              </Box>
              <div className="c-block__img">
                <img
                  src={"/images/how-to-use/img-06.png"}
                  alt="how-to-use-06"
                />
              </div>
            </div>
          </div>
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            手順⑤（作成したコレクションを確認する）
          </Box>
          <div className="c-content">
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                作成済みコレクションページを確認する
              </Box>
              <div className="c-block__img">
                <img
                  src={"/images/how-to-use/img-07.png"}
                  alt="how-to-use-07"
                />
              </div>
            </div>
          </div>
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            手順⑥（NFTを作る）
          </Box>
          <div className="c-content">
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                「NFTミント」をクリック
              </Box>
              <div className="c-block__img">
                <img
                  src={"/images/how-to-use/img-08.png"}
                  alt="how-to-use-08"
                />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                必須項目を入力しNFTを作成する
              </Box>
              <div className="c-block__img">
                <img
                  src={"/images/how-to-use/img-09.png"}
                  alt="how-to-use-09"
                />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                作成ボタンを押した後「署名」ボタンをクリック
              </Box>
              <div className="c-block__img">
                <img
                  src={"/images/how-to-use/img-10.png"}
                  alt="how-to-use-10"
                />
              </div>
            </div>
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                「確認」をクリックしてNFTを作成する
              </Box>
              <div className="c-block__img">
                <img
                  src={"/images/how-to-use/img-11.png"}
                  alt="how-to-use-11"
                />
              </div>
            </div>
          </div>
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            手順⑦（作成したNFTを確認する）
          </Box>
          <div className="c-content">
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                マイミントページを確認する
              </Box>
              <div className="c-block__img">
                <img
                  src={"/images/how-to-use/img-12.png"}
                  alt="how-to-use-12"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
